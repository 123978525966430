 .custom-card {
     transition: transform 0.3s;
     max-width: 100%;
     overflow: hidden;
     /* Ensure the card doesn't exceed the column's maximum width */
 }

 .custom-card:hover .text {

     opacity: 1;
     cursor: pointer;
     /* Ensure the card doesn't exceed the column's maximum width */
 }
.card-img{
    height: -webkit-fill-available;
}
.card-img-overlay{
    align-self: center;
}
 .custom-card:hover .custom-image {

     transform: scale(1.3);
     cursor: pointer;
     opacity: 0.3;
    height: -webkit-fill-available;
     /* Ensure the card doesn't exceed the column's maximum width */
 }

 .custom-image {
     transition: transform 1s;
 }
 .overlay-text {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     z-index: 2;
     opacity: 0;
     /* Place the overlay text above the image */
     transition: opacity 0.5s;
     /* Add a transition for opacity */
 }
 .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: none;
 }