.round-image {
    border-radius: 50%;
    /* Make the image round */
    width: 150px;
    /* Set the width as needed */
    height: 150px;
    /* Set the height as needed */
    object-fit: cover;
    /* Maintain aspect ratio and cover the container */
    /* Additional styles as needed */
}