.carousel-container {
    margin: 0 auto; /* Center the carousel */
  }
  
    .text-container {
      transition: font-size 0.3s ease;
    
      margin-bottom: 20%;
      /* Rotate text infinitely */
    }
      
    
        @keyframes scale3D {
          0% {
            transform: scale(2);
          }
    
          100% {
            transform: scale(1);
            /* Scale the text to 3 times its original size */
          }
        }
    .d-lg-block {
        animation: scale3D 2s  ;
        font-size: 40px;
        }
        .caption{
          
            line-height: 15px;
           
            letter-spacing: 10px;
            font-weight: 300;
            font-size: 15px;
            opacity: 1;
        }
        
                @media (max-width: 768px) {
                  .custom-image-md {
                    height: 400px !important;
                    width: 100% !important;
                    /* Apply margin on screens with a minimum width of 768px (medium screens) */
                  }
                                    
                }
                                @media (min-width: 768px) {
                                  .custom-image-md {
                                    width: 100% !important;
                                    height:"810px"
                                      /* Apply margin on screens with a minimum width of 768px (medium screens) */
                                  }
                                                                    .caption-title {
                                                                      font-size: 70px;
                                                                    }
                                }
               
        
                