
  

  .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      opacity: 0;
      /* Place the overlay text above the image */
      transition: opacity 0.5s;
      /* Add a transition for opacity */
  }
.owl-dots .active {
      opacity: 1;
  }
   .owl-dots  {
        background-clip: padding-box;
            background-color: #fff;
            border: 0;
            border-bottom: 10px solid transparent;
            border-top: 10px solid transparent;
            box-sizing: initial;
            cursor: pointer;
            flex: 0 1 auto;
            height: 3px;
            margin-left: 3px;
            margin-right: 3px;
            opacity: .5;
            padding: 0;
            text-indent: -999px;
            transition: opacity .6s ease;
            width: 30px;
  }
 

  
  .card-links {
      text-align: center;
  }



  .card-links button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: #007bff;
      /* Button color */
      text-decoration: none;
  }

  .card-links button:hover {
      color: #ff0000;
      /* Hovered button color */
  }

  /* Style for the active button */
  .card-links button.active {
      color: #ff0000;
      /* Active button color */
  }