.App {
  text-align: center;
}


body {
  
  background-color: black;
width: 100%;
height: 50%;
  background-attachment: fixed;
}




