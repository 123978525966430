
.background-image-container {
    background-image: url('../../public/images/AboutUs/2.living.jpg');
    background-size: cover;
    /* Adjust this property based on your needs */
    background-position: center;
    /* Adjust this property based on your needs */
    width: 100%;
    max-width: 100%;
    /* Set width and height to fill the container */
    height: 100%;
}
.MuiDialog-paper{
    box-shadow: none !important;
    background: none !important;
}
 .custom-card {
     transition: transform 0.3s;
     max-width: 100%;
     overflow: hidden;
     /* Ensure the card doesn't exceed the column's maximum width */
 }

 .custom-card:hover .text {

     opacity: 1;
     cursor: pointer;
     /* Ensure the card doesn't exceed the column's maximum width */
 }

 .custom-card:hover .custom-image {

     transform: scale(1.3);
     cursor: pointer;
     opacity: 0.3;
     /* Ensure the card doesn't exceed the column's maximum width */
 }

 .custom-image {
     transition: transform 1s;
 }

 .overlay-text {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     z-index: 2;
     opacity: 0;
     /* Place the overlay text above the image */
     transition: opacity 0.5s;
     /* Add a transition for opacity */
 }
 @keyframes shadowAnimation {
     0% {
         box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
     }

     50% {
         box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
     }

     100% {
         box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
     }
 }

 .play-icon-container {
     display: flex;
     justify-content: center;
     align-items: center;
     height: 100vh;
     /* Adjust as needed */
 }

 .animated-icon {
     animation: shadowAnimation 2s infinite;
    border-radius: 50%;
     /* Adjust the animation duration as needed */
 }
 @media (max-width: 768px) {
     .check {
         flex-wrap: wrap;
             /* Apply margin on screens with a minimum width of 768px (medium screens) */
     }
         .flow {
             flex-direction: column;
         }

    
 }
 .custom-link {
     color: white;
     /* Set the initial color */
     text-decoration: none;
     /* Remove underline, adjust as needed */
     transition: color 0.3s ease;
     /* Add a smooth transition effect */

     /* Additional styles as needed */
 }

 .custom-link:hover {
     background: blue;
     /* Set the color on hover */
     /* Additional styles as needed */
 }
 .table-black{
    --bs-table-bg:"black"
 }